import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { authorizeGoogleAction } from '../../state/user.reducer';
import { Loading } from '../../components/common/loading';
import { LINKS } from '../../constants/links';

const LoginGooglePage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const loginWithGoogle = () => {
		const queryString = typeof window !== 'undefined' && window.location.search;
		if (queryString) {
			dispatch(authorizeGoogleAction(queryString));
		} else {
			navigate(LINKS.HOMEPAGE);
		}
	};

	useEffect(() => {
		loginWithGoogle();
	}, []);

	return <Loading text={t('login.loginProcessLoading')} />;
};

export default LoginGooglePage;
